// Top Navbar===================
$(window).scroll(function() {    
    var scroll = $(window).scrollTop();
     //>=, not <=
    if (scroll >= 5) {
        //clearHeader, not clearheader - caps H
        $(".navbar-main").addClass("sticky-top");
    }
    else {
        $(".navbar-main").removeClass("sticky-top");
    }
});

// =====Auto focus on Homepage Search popup and Onboarding input for mobile
$("#modal-mobile-search, #modal-business-name, #modal-business-address").on('shown.bs.modal', function(){
    $(this).find('input[type="text"]').focus();
});